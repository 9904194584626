




























































































































































































import {Vue, Component} from "vue-property-decorator";
import api from '@/api'

@Component({
    name: "couponAgentD",
    components: {}
})
export default class extends Vue {
    private tableLoading = false;
    private pages = 0;
    private currentPage = 1;

    private searchLoading = false;
    private search = {
      timeSpan: 3,
      shareTime: [],
      brokerId: '',
      couponNumber: ''
    };


    protected changeDate(e: any) {
        this.search.timeSpan = e
        this.queryrecord()
    }

    private couponData: any = []
    private countHeld: any = []

    protected COUPONDETAIL() {
      api.post('J27302', {couponNumber: this.$route.query.couponNumber})
          .then((res) => {
            this.couponData = res.list
          })

    }

  queryrecord() {
    this.search.couponNumber = this.$route.query.couponNumber as string
    api.post('J27311', this.search)
        .then((res) => {
          this.countHeld = res.list
        })
  }

  activated() {
      this.COUPONDETAIL()
    this.queryrecord()
  }


    /*日志*/
    shareDialog = false
  shareTotal = 0
    shareTable = []
  shareSearch = {
    pagination: {
      page: 0,
      pageSize: 20
    }
  }
  // this.search
  brokerId = ''
    protected async showLogDialog(brokerId: string, data: any) {
      this.brokerId = brokerId
      api.post('J27312', {couponNumber: this.search.couponNumber,brokerId:brokerId})
          .then(res => {
            this.shareTable = res.list
            this.shareTotal = res.total
            this.shareDialog = true
          })

    }
  changePage(page: number) {
      this.shareSearch.pagination.page = page - 1;
    api.post('J27312', {couponNumber: this.search.couponNumber,brokerId:this.brokerId})
        .then(res => {
          this.shareTable = res.list
          this.shareTotal = res.total
        })
    }
    CloseShareDialog() {
        this.shareDialog = false
    }

}
